import React from "react"
import PropTypes from "prop-types"
import { useNotion } from "../../hooks/use-notion"
import { Link } from "gatsby"

const Remarque = props => {
  //console.log(props)
  return(
    <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
      <div className="flex">
        <div className="py-1">
          <svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20">
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
          <p className="font-bold">Remarque</p>
          <p className="text-sm">Make sure you know how these changes affect you.</p>
        </div>
      </div>
    </div>
  )
}

const Chapter = props => {
  const listNotions = useNotion()
  let text = ""

  listNotions.forEach(notion => {

    function makeLink(match, offset, string){
      let link = `<a href="/notion/${notion.node.slug}" class='link-def'>${match}</a>`
      //let result = link.setAttribute('class', 'link-def')
      // console.log(result)
      return  link
    }

    let search = notion.node.title.replace(/(le\s)|(la\s)|(l')/gi, '')
    let regex = new RegExp(search, 'gi')
    //let match = props.content.match(regex, 'gi')
    //console.log(match.index)
    text = props.content.replace(regex, makeLink)
  })

  const renderers = {
    remarque: Remarque
  }

  return (
    <div className={'mx-auto prose lg:prose-xl px-5'}>
      <h1>{props.title}</h1>
      <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <svg className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"/>
          </svg>
          {props.updated}
        </div>
          <Link to={`/cours/${props.course.level.slug}/${props.course.slug}`} className="mt-2 flex items-center text-sm text-gray-500">
          <svg className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
          </svg>
          in {props.course.title}
          </Link>
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <svg className="flex-shrink-0 mr-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
          </svg>
          {props.course.level.title}
        </div>
      </div>
      <div className="section-chapter" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  )
}

Chapter.propTypes = {}

export default Chapter
