import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Chapter from "../components/course/chapter"
import SEO from "../components/seo"
import { getChapterExcerpt } from "../utils/get-excerpt"

export default function ChapterPage({ data, pageContext }) {
  const course = data.strapiCourse
  const sortedChapter = course.chapters.sort((a, b) =>
    a.title.localeCompare(b.title)
  )

  const currentChapter = sortedChapter.find(
    chapter => chapter.slug === pageContext.slugChapter
  )

  const indexOfCurrent = sortedChapter.indexOf(currentChapter)
  const nextChapter =
    indexOfCurrent < sortedChapter.length
      ? course.chapters[indexOfCurrent + 1]
      : null
  const previousChapter =
    indexOfCurrent > 0 ? sortedChapter[indexOfCurrent - 1] : null

  const description = getChapterExcerpt(currentChapter)

  return (
    <Layout>
      <SEO
        title={currentChapter.title}
        description={description}
        canonical={`/cours/${course.level.slug}/${course.slug}/${currentChapter.slug}/`}
      />
      <article
        className={"relative my-16 border border-gray-200 bg-white py-12 shadow-lg"}
      >
        <Link
          to={`/cours/${course.level.slug}/${course.slug}/`}
          type="button"
          aria-label="Go back to articles"
          className="group mb-8 flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 transition lg:absolute lg:-left-5 lg:mb-0 lg:mt-2 xl:top-5 xl:left-5 xl:mt-0"
        >
          <svg
            viewBox="0 0 16 16"
            fill="none"
            aria-hidden="true"
            className="h-4 w-4 stroke-zinc-500 transition group-hover:stroke-zinc-700 dark:stroke-zinc-500 dark:group-hover:stroke-zinc-400"
          >
            <path
              d="M7.25 11.25 3.75 8m0 0 3.5-3.25M3.75 8h8.5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </Link>
        <Chapter
          title={currentChapter.title}
          content={currentChapter.content}
          thumbnail={currentChapter.featured_media}
          course={course}
          updated={currentChapter.updatedAt}
        />
      </article>
      <div className="navigation mx-auto grid max-w-prose grid-cols-2 gap-6">
        {previousChapter && (
          <Link
            to={`/cours/${course.level.slug}/${course.slug}/${previousChapter.slug}`}
            //to={"/cours/" + course.slug + "/" + previousChapter.slug}
            className="previous btn btn-blue"
          >
            {previousChapter.title}
          </Link>
        )}
        {previousChapter == null && (
          <Link
            to={`/cours/${course.level.slug}/${course.slug}`}
            className="previou btn btn-blue"
          >
            {course.title}
          </Link>
        )}
        {nextChapter && (
          <Link
            to={`/cours/${course.level.slug}/${course.slug}/${nextChapter.slug}`}
            className="next btn btn-blue"
          >
            {nextChapter.title}
          </Link>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiCourse(slug: { eq: $slug }) {
      slug
      title
      featured_media {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      chapters {
        title
        slug
        content
        updatedAt(locale: "fr", formatString: "D MMMM YYYY")
      }
      level {
        title
        slug
      }
    }
  }
`
