import { useStaticQuery, graphql } from "gatsby"

export const useNotion = () => {
  const { allStrapiNotion } = useStaticQuery(
    graphql`
      query notion {
        allStrapiNotion {
          edges {
            node {
              title
              slug
              definition
            }
          }
        }
      }
    `
  )
  return allStrapiNotion.edges
}
